import Button from "@ingka/button"
import { useState } from "react"
import { TimeSlot } from "@/api/getTimeSlots"
import { getTimeFromDateTime } from "@/utils/formatDateTime"
import { useTranslation } from "react-i18next"
import { FullServeStatus } from "./FullServeStatusText"
import { TimeSlotsModal } from "./TimeSlotsModal"
import { useSession } from "@/hooks/useListig"
import { useGetTimeSlots } from "@/hooks/useGetTimeSlots"

interface TimeSlotOrderViewProps {
  status?: FullServeStatus
  timeSlot: TimeSlot
}

export const TimeSlotOrderView: React.FC<TimeSlotOrderViewProps> = ({
  status,
  timeSlot,
}) => {
  const { t } = useTranslation()
  const { session } = useSession()
  const { data: timeSlots } = useGetTimeSlots(session.businessUnitCode, [
    { itemId: "10504024", quantity: 1 }, // TODO: change this to pass in order number instead of products?
  ])

  const [showTimeSlotModal, setShowTimeSlotModal] = useState<boolean>(false)

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div>
          <div className="text-sm">{t("mobile.collection-time")}:</div>
          <div className="text-xl pb-2 pt-1">
            {getTimeFromDateTime(timeSlot.fromDateTime) +
              " - " +
              getTimeFromDateTime(timeSlot.toDateTime)}
          </div>
        </div>

        {timeSlots && timeSlots.length > 1 && (
          <div>
            <Button
              type="tertiary"
              className="bg-neutral-100"
              size="small"
              onClick={() => setShowTimeSlotModal(true)}
            >
              {t("common.change")}
            </Button>

            <TimeSlotsModal
              timeSlots={timeSlots}
              chosenTimeSlotId={
                timeSlots.find((ts) => ts.id === timeSlot.id)?.id ??
                timeSlots[0]?.id
              }
              show={showTimeSlotModal}
              onClose={() => setShowTimeSlotModal(false)}
            />
          </div>
        )}
      </div>

      {status === "WAITING_FOR_PAYMENT" && (
        <div className="text-sm">
          {t("mobile.pay-later-new-time", {
            time: getTimeFromDateTime(timeSlot.paymentCutOffTime),
          })}
        </div>
      )}
    </div>
  )
}
