import { SlideUpModal } from "../modals/SlideUpModal"
import { useState } from "react"
import { TimeSlots } from "./TimeSlots"
import { TimeSlot } from "@/api/getTimeSlots"

export const TimeSlotsModal: React.FC<{
  timeSlots: TimeSlot[]
  chosenTimeSlotId?: string
  show: boolean
  onClose: () => void
}> = ({ timeSlots, chosenTimeSlotId, show, onClose }) => {
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<
    string | undefined
  >(chosenTimeSlotId)

  return (
    <SlideUpModal
      onClose={() => {
        onClose()
        setSelectedTimeSlotId(chosenTimeSlotId)
      }}
      showing={show}
    >
      <TimeSlots
        selectedTimeSlotId={selectedTimeSlotId}
        setSelectedTimeSlotId={setSelectedTimeSlotId}
        timeSlots={timeSlots}
        onContinue={() => {
          // TODO: send new time slot to buy api
          onClose()
        }}
      />
    </SlideUpModal>
  )
}
