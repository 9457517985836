import { useAuth } from "./useAuth"
import { useLocale } from "./useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { getTimeSlots } from "@/api/getTimeSlots"

export const useGetTimeSlots = (
  buCode?: string,
  items?: { itemId: string; quantity: number }[],
) => {
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()

  const timeSlotsQuery = useQueryWithErrorHandling(
    [buCode, items, market, language],
    () =>
      oAuthToken && buCode && items
        ? getTimeSlots(market, language, oAuthToken, buCode, items)
        : Promise.reject("Missing data"),
    {
      enabled: !!oAuthToken && !!buCode && !!items,
      gcTime: Infinity,
      staleTime: Infinity,
    },
    "error",
  )

  return { data: timeSlotsQuery.data }
}
