export interface ValidationRule {
  field: string
  regex?: {
    regexp: string
    flag: string
  }
  maxLength?: number
  minLength?: number
  mandatory?: boolean
  acceptedValues?: string[]
  acceptedValuesRegex?: {
    regexp: string
    flag: string
  }
}

export const validateField = (
  value: string,
  field: string,
  validationRules: ValidationRule[],
): boolean => {
  const rule = validationRules.find((rule) => rule.field === field)

  if (rule?.regex) {
    const regex = new RegExp(rule.regex.regexp, rule.regex.flag)
    return regex.test(value)
  }
  return false
}
