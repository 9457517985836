import { CAAS_ENABLED, ENABLED_CAAS_LOCATIONS } from "@/config/constants"
import { ListigList } from "@/types/listig"
import { ProductType } from "@/types/product/products"
import { ExpiredListError } from "@/utils/errors"
import { fetchWithErrorHandling } from "@/utils/fetch"
import { isEnabled } from "@/utils/isEnabled"
import { isExpired } from "@/utils/localStorage"

export async function getListigList(
  listId: string,
  oAuthToken: string,
): Promise<ListigList> {
  if (isExpired(listId)) {
    throw new ExpiredListError()
  }

  return fetchWithErrorHandling(
    "LISTIG_GET",
    `${process.env.LISTIG_API_URL}/v2/lists/${listId}`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
      },
    },
    (response) => {
      if (response.status === 404) {
        throw new ExpiredListError()
      }
    },
  )
}

/**
 * Creates a new listig list.
 * @throws TypeError on network error
 * @throws ApiError on HTTP error
 * @returns The created list
 */
export async function createListigList(
  items: { id: string; type?: string; quantity: number }[],
  market: string,
  language: string,
  classUnit: { type: "ru" | "sto"; code: string },
  oAuthToken: string,
  user: string,
): Promise<ListigList> {
  const caasEnabled = isEnabled({
    market,
    businessUnitCode: classUnit.code,
    ukid: user,
    ENABLED: CAAS_ENABLED,
    ENABLED_LOCATIONS: ENABLED_CAAS_LOCATIONS,
  })

  const queryParam = new URLSearchParams()
  if (caasEnabled) {
    queryParam.append("caas", "true")
  }

  return fetchWithErrorHandling(
    "LISTIG_CREATION",
    `${process.env.LISTIG_API_URL}/lists?${queryParam.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        businessUnit: {
          market,
          language,
          code: classUnit.code,
          type: classUnit.type,
        },
        system: "instore",
        user,
        items,
      }),
    },
  )
}

/**
 * Adds items to list
 * @throws ApiError or TypeError if request fail
 */
export const addProducts = (
  listId: string,
  products: ListigList["items"],
  oAuthToken: string,
): Promise<ListigList> =>
  fetchWithErrorHandling(
    "LISTIG_ADD",
    `${process.env.LISTIG_API_URL}/lists/${listId}/items`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(products),
    },
  )

/**
 * Adds an order to a list
 * @throws ApiError or TypeError if request fail
 */
export const addOrder = (
  listId: string,
  orderNo: string,
  orderNoSource: string,
  items: ListigList["items"],
  oAuthToken: string,
): Promise<ListigList> =>
  fetchWithErrorHandling(
    "LISTIG_ADD_ORDER",
    `${process.env.LISTIG_API_URL}/lists/${listId}/orders`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        orderNo: orderNo,
        orderNoSource: orderNoSource,
        orderDate: new Date().toISOString(),
        items,
      }),
    },
  )

/**
 * Update the item quantity.
 * @throws ApiError or TypeError if request fail
 */
export const updateListigProduct = (
  listId: string,
  productId: string,
  productType: ProductType,
  quantity: number,
  oAuthToken: string,
): Promise<ListigList> =>
  fetchWithErrorHandling(
    "LISTIG_UPDATE",
    `${process.env.LISTIG_API_URL}/lists/${listId}/items/${productType}/${productId}`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify({ quantity }),
    },
  )

/**
 * Deletes item from list
 * @throws ApiError or TypeError if request fail
 */
export const deleteListigProduct = (
  listId: string,
  productId: string,
  productType: ProductType,
  oAuthToken: string,
): Promise<ListigList> =>
  fetchWithErrorHandling(
    "LISTIG_DELETE",
    `${process.env.LISTIG_API_URL}/lists/${listId}/items/${productType}/${productId}`,
    {
      headers: {
        Authorization: `Bearer ${oAuthToken}`,
      },
      method: "DELETE",
    },
  )
