import { fetchWithErrorHandling } from "@/utils/fetch"

export type TimeSlot = {
  id: string
  fromDateTime: string
  toDateTime: string
  paymentCutOffTime: string
  hardCutOffTime: string
}

export const getTimeSlots = (
  market: string,
  language: string,
  token: string,
  buCode: string,
  items: { itemId: string; quantity: number }[],
): Promise<TimeSlot[]> => {
  return fetchWithErrorHandling(
    "BUY_GET_TIME_SLOTS",
    `${process.env.BUY_API_URL}/time-slots/${market}/${language}/${buCode}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ items }),
    },
  )
}
