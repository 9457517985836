import { addOrder } from "@/api/listig"
import { useAuth } from "@/hooks/useAuth"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { ListigItem } from "@/types/listig"

/**
 * Provides a function to add an order to the list,
 * will automatically refetch the list afterwards.
 * @returns a function to add an order to listig list
 */
export function useAddOrder(listId: string | null) {
  const { oAuthToken } = useAuth()
  const queryClient = useQueryClient()

  /** mutation to add an order to listig and refresh on success */
  const mutation = useMutationWithErrorHandling(
    (input: {
      listId: string | null
      orderNo: string | null
      orderNoSource: string | null
      items: ListigItem[] | null
    }) => {
      return input.listId &&
        input.orderNo &&
        input.orderNoSource &&
        input.items &&
        oAuthToken
        ? addOrder(
            input.listId,
            input.orderNo,
            input.orderNoSource,
            input.items,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing fields"))
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ["listig", listId?.toString()],
        }),
    },
  )

  const mutate = mutation.mutate
  const addListigOrder = useCallback(
    (
      listId: string | null,
      orderNo: string | null,
      orderNoSource: string | null,
      items: ListigItem[] | null,
      options?: {
        onSuccess?: () => void
      },
    ) =>
      mutate(
        { listId, orderNo, orderNoSource, items },
        { onSuccess: options?.onSuccess },
      ),
    [mutate],
  )

  return {
    /** add an order to list and automatically reload list */
    add: addListigOrder,
    /** error when adding order */
    error: mutation.error,
  }
}
