import Button from "@ingka/button"
import { useState } from "react"
import { TimeSlot } from "@/api/getTimeSlots"
import { getTimeFromDateTime } from "@/utils/formatDateTime"
import { Trans, useTranslation } from "react-i18next"
import { FullServeStatus } from "./FullServeStatusText"
import { TimeSlotsModal } from "./TimeSlotsModal"
import { useGetTimeSlots } from "@/hooks/useGetTimeSlots"
import { useSession } from "@/hooks/useListig"

interface TimeSlotOrderCardProps {
  status?: FullServeStatus
  timeSlot: TimeSlot
}

export const TimeSlotOrderCard: React.FC<TimeSlotOrderCardProps> = ({
  status,
  timeSlot,
}) => {
  const { t } = useTranslation()
  const { session } = useSession()
  const { data: timeSlots } = useGetTimeSlots(session.businessUnitCode, [
    { itemId: "10504024", quantity: 1 }, // TODO: change this to pass in order number instead of products?
  ])

  const [showTimeSlotModal, setShowTimeSlotModal] = useState<boolean>(false)

  const collectionTime =
    getTimeFromDateTime(timeSlot.fromDateTime) +
    " - " +
    getTimeFromDateTime(timeSlot.toDateTime)

  return (
    <div className="flex flex-row justify-between">
      {status === "WAITING_FOR_PAYMENT" ? (
        <div className="text-sm">
          <Trans
            i18nKey={t("mobile.pay-before-and-collect", {
              payTime: getTimeFromDateTime(timeSlot.paymentCutOffTime),
              collectTime: collectionTime,
            })}
          />
        </div>
      ) : (
        <div>
          <div className="text-sm">{t("mobile.collection-time")}:</div>
          <div className="text-xl pt-1">{collectionTime}</div>
        </div>
      )}

      {timeSlots && timeSlots.length > 1 && (
        <div>
          <Button
            type="tertiary"
            className="bg-neutral-100"
            size="small"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setShowTimeSlotModal(true)
            }}
          >
            {t("common.change")}
          </Button>

          <TimeSlotsModal
            timeSlots={timeSlots}
            chosenTimeSlotId={
              timeSlots.find((ts) => ts.id === timeSlot.id)?.id ??
              timeSlots[0]?.id
            }
            show={showTimeSlotModal}
            onClose={() => setShowTimeSlotModal(false)}
          />
        </div>
      )}
    </div>
  )
}
