import Button from "@ingka/button"
import { ModalFooter } from "@ingka/modal"
import { useTranslation } from "react-i18next"
import { SlideUpModal } from "../modals/SlideUpModal"
import InputField from "@ingka/input-field"
import FormField from "@ingka/form-field"
import { useState } from "react"
import { PrivacyPolicyModal } from "../modals/PrivacyPolicyModal"
import { getValidationRulesByField } from "@ingka-group-digital/customer-fields-validation-rules/dist/validator/validationRulesExtractor"
import { CreateCashCarryOrderBody } from "@/types/responses/buy"
import { validateField, ValidationRule } from "@/utils/validateField"

/**
 * Prompt to collect customer profile information for full serve order with tax point delivery
 */
export const CaptureProfileModal: React.FC<{
  show: boolean
  onContinue: (customer?: CreateCashCarryOrderBody["customer"]) => void
  onCancel: () => void
  market: string
  language: string
}> = ({ show, onContinue, onCancel, market, language }) => {
  const { t } = useTranslation()

  const [showPolicy, setShowPolicy] = useState<boolean>(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [streetName, setStreetName] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [city, setCity] = useState("")

  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true)
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true)
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [isStreetNameValid, setIsStreetNameValid] = useState<boolean>(true)
  const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(true)
  const [isCityValid, setIsCityValid] = useState<boolean>(true)

  const [shouldValidateFirstName, setShouldValidateFirstName] = useState(false)
  const [shouldValidateLastName, setShouldValidateLastName] = useState(false)
  const [shouldValidateStreetName, setShouldValidateStreetName] =
    useState(false)
  const [shouldValidateEmail, setShouldValidateEmail] = useState(false)
  const [shouldValidatePostalCode, setShouldValidatePostalCode] =
    useState(false)
  const [shouldValidateCity, setShouldValidateCity] = useState(false)

  const validationRules = getValidationRulesByField(
    ["FIRST_NAME", "LAST_NAME", "ADDRESS_LINE1", "POST_CODE", "CITY", "EMAIL"],
    market,
  ) as ValidationRule[]

  const validateAllFields = () => {
    const firstNameValid = validateField(
      firstName,
      "firstName",
      validationRules,
    )
    const lastNameValid = validateField(lastName, "lastName", validationRules)
    const emailValid = validateField(email, "email", validationRules)
    const streetNameValid = validateField(
      streetName,
      "addressLine1",
      validationRules,
    )
    const postalCodeValid = validateField(
      postalCode,
      "zipCode",
      validationRules,
    )
    const cityValid = validateField(city, "city", validationRules)

    setIsFirstNameValid(firstNameValid)
    setIsLastNameValid(lastNameValid)
    setIsEmailValid(emailValid)
    setIsStreetNameValid(streetNameValid)
    setIsPostalCodeValid(postalCodeValid)
    setIsCityValid(cityValid)

    return {
      firstNameValid,
      lastNameValid,
      emailValid,
      streetNameValid,
      postalCodeValid,
      cityValid,
    }
  }

  const handleProfileSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const {
      firstNameValid,
      lastNameValid,
      emailValid,
      streetNameValid,
      postalCodeValid,
      cityValid,
    } = validateAllFields()

    if (
      firstNameValid &&
      lastNameValid &&
      emailValid &&
      streetNameValid &&
      postalCodeValid &&
      cityValid
    ) {
      onContinue({
        firstName,
        lastName,
        emailAddress: email,
        addressLine1: streetName,
        zipCode: postalCode,
        city,
        countryCode: market,
      })
    } else {
      if (!firstNameValid) setShouldValidateFirstName(true)
      if (!lastNameValid) setShouldValidateLastName(true)
      if (!emailValid) setShouldValidateEmail(true)
      if (!streetNameValid) setShouldValidateStreetName(true)
      if (!postalCodeValid) setShouldValidatePostalCode(true)
      if (!cityValid) setShouldValidateCity(true)
    }
  }

  const handleBlur = (field: string) => {
    switch (field) {
      case "firstName":
        setShouldValidateFirstName(true)
        setIsFirstNameValid(
          validateField(firstName, "firstName", validationRules),
        )
        break
      case "lastName":
        setShouldValidateLastName(true)
        setIsLastNameValid(validateField(lastName, "lastName", validationRules))
        break
      case "email":
        setShouldValidateEmail(true)
        setIsEmailValid(validateField(email, "email", validationRules))
        break
      case "streetName":
        setShouldValidateStreetName(true)
        setIsStreetNameValid(
          validateField(streetName, "addressLine1", validationRules),
        )
        break
      case "postalCode":
        setShouldValidatePostalCode(true)
        setIsPostalCodeValid(
          validateField(postalCode, "zipCode", validationRules),
        )
        break
      case "city":
        setShouldValidateCity(true)
        setIsCityValid(validateField(city, "city", validationRules))
        break
      default:
        break
    }
  }

  return (
    <SlideUpModal onClose={onCancel} showing={show}>
      <h2 className="text-2xl mb-12" data-cy="modal-header">
        {t("mobile.ready-to-order")}

        <p className="text-sm font-thin pt-3">
          {t("mobile.profile-capture-info")}
        </p>
      </h2>

      <>
        <form onSubmit={handleProfileSubmit}>
          <div className="space-y-4">
            <FormField
              shouldValidate={shouldValidateFirstName}
              valid={isFirstNameValid}
              validation={{
                msg:
                  firstName === ""
                    ? "First name is mandatory"
                    : "Your First name can't contain numbers or special characters",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                label="First name"
                id="first-name"
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                  setShouldValidateFirstName(false)
                }}
                onBlur={() => handleBlur("firstName")}
                inputMode="text"
                data-cy="input-field-email"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateLastName}
              valid={isLastNameValid}
              validation={{
                msg:
                  lastName === ""
                    ? "Last name is mandatory"
                    : "Your Last name can't contain numbers or special characters",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="last-name"
                label="Last name"
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                  setShouldValidateLastName(false)
                }}
                onBlur={() => handleBlur("lastName")}
                inputMode="text"
                data-cy="input-field-email"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateEmail}
              valid={isEmailValid}
              validation={{
                msg:
                  email === ""
                    ? "Email is mandatory"
                    : "Your email address is not valid",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="email"
                label="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setShouldValidateEmail(false)
                }}
                onBlur={() => handleBlur("email")}
                inputMode="email"
                data-cy="input-field-email"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateStreetName}
              valid={isStreetNameValid}
              validation={{
                msg:
                  streetName === ""
                    ? "Street name is mandatory"
                    : "This is not a valid Street name",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="street-name"
                label="Street name"
                type="text"
                value={streetName}
                onChange={(e) => {
                  setStreetName(e.target.value)
                  setShouldValidateStreetName(false)
                }}
                onBlur={() => handleBlur("streetName")}
                inputMode="text"
                data-cy="input-field-email"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidatePostalCode}
              valid={isPostalCodeValid}
              validation={{
                msg:
                  postalCode === ""
                    ? "Postal code is mandatory"
                    : "This is not a valid postal code",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="postal-code"
                label="Postal code"
                type="text"
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value)
                  setShouldValidatePostalCode(false)
                }}
                onBlur={() => handleBlur("postalCode")}
                inputMode="text"
                data-cy="input-field-email"
              />
            </FormField>

            <FormField
              shouldValidate={shouldValidateCity}
              valid={isCityValid}
              validation={{
                msg:
                  city === ""
                    ? "City is mandatory"
                    : "This is not a valid City name",
                id: "profile",
                type: "error",
              }}
            >
              <InputField
                id="city"
                label="City"
                type="text"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value)
                  setShouldValidateCity(false)
                }}
                onBlur={() => handleBlur("city")}
                inputMode="text"
                data-cy="input-field-email"
              />
            </FormField>
          </div>
        </form>

        <div className="pt-7">
          <p className="text-xs text-gray-400 mt-0 mb-10">
            {t("mobile.show-privacy-policy")}&nbsp;
            <span onClick={() => setShowPolicy(true)} className="underline">
              {t("common.privacy-policy-anchor-text")}
            </span>
          </p>
          <PrivacyPolicyModal
            showPolicy={showPolicy}
            onClose={() => setShowPolicy(false)}
            market={market}
            language={language}
          />
        </div>
      </>

      <ModalFooter className="sticky bottom-0 bg-white z-10 px-0 py-4">
        <Button text={t("mobile.not-yet")} onClick={onCancel} />
        <Button
          data-cy="confirm-order-button"
          type="emphasised"
          text={t("mobile.place-order")}
          htmlType="submit"
          onClick={(e: React.MouseEvent) => {
            handleProfileSubmit(e)
          }}
        />
      </ModalFooter>
    </SlideUpModal>
  )
}
