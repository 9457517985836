import { Choice, ChoiceItem } from "@ingka/choice"
import { TimeSlot } from "@/api/getTimeSlots"
import Button from "@ingka/button"
import checkmark from "@ingka/ssr-icon/paths/checkmark"
import { t } from "i18next"
import { getTimeFromDateTime } from "@/utils/formatDateTime"

type TimeSlotsProps = {
  selectedTimeSlotId?: string
  setSelectedTimeSlotId: (id: string) => void
  timeSlots: TimeSlot[]
  onContinue: () => void
}

export const TimeSlots: React.FC<TimeSlotsProps> = ({
  selectedTimeSlotId,
  setSelectedTimeSlotId,
  timeSlots,
  onContinue,
}) => {
  return (
    <>
      <div className="text-2xl font-bold text-black pb-6">
        {t("mobile.choose-collection-time")}
      </div>
      <Choice
        onClick={(e) => e.stopPropagation()}
        subtle
        preselectedIndex={
          timeSlots.findIndex(
            (timeSlot) => timeSlot.id === selectedTimeSlotId,
          ) ?? 0
        }
      >
        {timeSlots.map((timeSlot, index) => (
          <ChoiceItem
            key={index}
            title={
              getTimeFromDateTime(timeSlot.fromDateTime) +
              " - " +
              getTimeFromDateTime(timeSlot.toDateTime)
            }
            caption={
              selectedTimeSlotId === timeSlot.id
                ? t("mobile.pay-before", {
                    time: getTimeFromDateTime(timeSlot.paymentCutOffTime),
                  })
                : ""
            }
            id={timeSlot.id}
            imagePosition="trailing"
            ssrIcon={selectedTimeSlotId === timeSlot.id ? checkmark : undefined}
            onClick={(id: string) => setSelectedTimeSlotId(id)}
          />
        ))}
      </Choice>
      <Button
        type="primary"
        className="w-full"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          onContinue()
        }}
      >
        {t("common.continue")}
      </Button>
    </>
  )
}
